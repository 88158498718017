<template>
  <div class="about">
    <Note />
    <Partnership />
    <!-- <Employtec /> -->
  </div>
</template>

<script>
import Note from "./components/Note.vue";
import Partnership from "./components/Partnership.vue";
// import Employtec from "./components/Employtec.vue";

export default {
  name: "About",
  components: {
    Note,
    Partnership,
    // Employtec,
  },
};
</script>

<style lang="scss" scoped></style>
