<template>
  <div id="note" class="pb-12">
    <div class="pb-10">
      <v-container class="content">
        <h3 class="display-2 font-weight-bold primary--text">
          A note from the founders
        </h3>
      </v-container>
    </div>
    <v-container class="content">
      <v-row>
        <v-col cols="12">
          <blockquote class="blockquote font-italic quote">
            At the start of the lockdown, we were unable to get through to our
            favourite independent shops on the phone, saw a problem and wanted
            to help. The following month www.wearebs3.co.uk was launched and had
            13,000 visits in the first two weeks and over £25,000 of sales in
            the first month without a penny spent on advertising. In the short
            term during Covid we simply wanted to quickly offer a user friendly
            digital marketplace to connect all things local. We created the
            platform because we believe in the sense of place and community that
            a high street brings and we want to support them long term. We hope
            you will join us and help shape the platform going forward as we aim
            to make clicks and bricks equal much more than the sum of the parts.
            This is just the start – there are many innovations planned all at
            no extra charge. Our mission is simple… no rest until traditional
            high street businesses have the tech capabilities of Amazon. We
            believe this combined with inherent local advantage will enable high
            streets to compete in the ‘new normal’ and longer term.
          </blockquote>
          <p class="text-right mt-10 primary--text">
            <span class="font-weight-bold title">Phil and Christian</span><br />
            <span> high street shoppers and tech-providers</span>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Note",
};
</script>

<style scoped>
.quote {
  font-size: inherit;
}
</style>
