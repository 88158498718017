<template>
  <div id="partnership" class="grey lighten-3 py-12">
    <v-container class="content">
      <h3 class="display-2 font-weight-bold mb-10 primary--text">
        Partnerships
      </h3>
      <v-row>
        <v-col cols="12" md="6">
          <p class="py-12">
            We Are Locals has partnered with Business Improvement Districts,
            Local Councils and combined authorities to support local businesses
            and neighbourhoods to push the narrative of Discover and Shop Local.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div class="full-height full-width img-left-skew">
      <v-img
        aspect-ratio="591/400"
        position="right"
        class="flex-grow-0"
        height="100%"
        width="50%"
        :src="require('@/assets/partnership.png')"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#partnership {
  position: relative;
  .img-left-skew {
    display: none;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      clip-path: polygon(50% 0, 100% 0%, 100% 100%, 60% 100%);
    }
  }
}
</style>
